

import ApiFile from 'src/apis/file';

        //////// Invoke when user will try to see uploaded document. ////////
const FilesHandlers = ()=>{    
    const Api_File = ApiFile()

    const openFile = (fileName, isDownload, target="_blank")=>{    
        Api_File.getViewFile(fileName)
        .then((response)=>{
            let data = response.data;
            
            if (data.status == 1) {
                if (isDownload == true) {
                    console.log("data", data, "download true");
                    const url = window.URL.createObjectURL(new Blob([data.response.signed_url]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${docId.originalName}`);
                    document.body.appendChild(link);
                    link.click();  
                }else {
                    window.open(data.response.signed_url, target)  
                }
            } 
        })
        .catch((errors) => {  
            // catchErrorsController(" Vehicle Doc File Generate Get Info --- ", errors);
        }) 
    }

    return {openFile};
}

export default FilesHandlers;