import { useState, useEffect, useCallback } from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CButtonGroup,
  CButton,
  CAlert,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import useApiMessageHandler from "src/helpers/useApiMessageHandler";
import { loaderDisable, loaderEnable } from "src/store/features/loaderSlice";

import DataTable from "../shared/data_table";
import ApiUser from "../../apis/users";
import { useNavigate } from "react-router-dom";
import { setUserData } from "src/store/features/userdataSlice";
import {
  resetDivisionStore,
  updatedivisionstore,
} from "src/store/features/divisionSlice";
import { setSkipApiResponseProcess } from "src/store/features/skipApiResponseProcessSlice";
import { useAuth } from "src/helpers/useAuth";

const secure_salt_key = process.env.REACT_APP_SECURE_SALT_KEY;
const NO_FLEET_ACCESS_STATUS = process.env.REACT_APP_NO_FLEET_ACCESS_STATUS;
const CryptoJS = require("crypto-js");

const User = () => {
  const Api_User = ApiUser();
  const authHelper = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAnyError, setIsAnyError] = useState(false);
  const { backendMessagesController, catchErrorsController } =
    useApiMessageHandler();

 

  useEffect(() => {
     
    Api_User.getAll()
      .then((response) => {
        dispatch(loaderDisable()); 
        dispatch(setSkipApiResponseProcess(0));
        let data = response.data;

        if (data.status == NO_FLEET_ACCESS_STATUS) {
          navigate(`/accessNotAuth?url=${data.response.d3_url}`);
        } else if (data.status == 1) {
          let userdata = data.response.user_details;
          let access_level = userdata.access_level;

          const encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(access_level),
            secure_salt_key
          ).toString();

          userdata.access_level = encrypted;

          let divisionPayload = {
            division_id: null,
            division_name: null,
            company_id: null,
            company_name: null,
            dotco_id: null,
            dotco_name: null,
          }; 

          dispatch(resetDivisionStore());
          dispatch(setUserData(userdata));

          navigate("/vehicle-status");
        } else {
          console.log('after api response else section --- authHelper.logout() triggered (user.js)');
          authHelper.logout();
          setIsAnyError(true);
        }
        backendMessagesController([data]);
      })
      .catch((errors) => {
        // console.log("---- Redirecting ---", errors);
        catchErrorsController(" Userdata in Form --- ", errors);
      });
  }, []);
 
  return <>{isAnyError ? "Somthing went wrong" : "Redirecting..."}</>;
};

export default User;
