import { createSlice } from "@reduxjs/toolkit"; 
 
const userdataSlice = createSlice({
    name : 'userdata',
    initialState: [],
    reducers: {
        setUserData(state, action){  
            state[0] = action.payload
        },
        getUserData(state, action){ 
            state[0] = action.payload
        },
        updateUserData(state, action){ 
            state[0] = action.payload
        },
    }
})

// console.log('toastSlice', toastSlice);
 
export const {setUserData, getUserData, updateUserData} = userdataSlice.actions
export default userdataSlice.reducer;