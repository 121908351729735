import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice';
 

const ApiAutoSuggestions = () => {

  
  const dispatch = useDispatch()
  const divisionReduxData = useSelector((state)=>state.division)[0]   
  const {http} = httpData(divisionReduxData.division_id)
 
  const division_param = {
    division_id : divisionReduxData.division_id, 
  }

  // const getAll = () => {
  //   dispatch(loaderEnable())  
  //   return http.get('/auto_suggestions')
  //   .finally(()=>dispatch(loaderDisable()));
  // };

  const getById = (data, entityType="divisions") => { 
    return http.get(`/auto_suggestions?entity_type=${entityType}&term=${data}`, {params : division_param})
    .finally();
  };

  // const add = (data) => {
  //   dispatch(loaderEnable())  
  //   return http.post(`/auto_suggestions`, data)
  //   .finally(()=>dispatch(loaderDisable()));
  // };

  // const update = (data) => {
  //   dispatch(loaderEnable())  
  //   return http.put(`/auto_suggestions`, data)
  //   .finally(()=>dispatch(loaderDisable()));
  // };

  // const remove = (dataObj) => {
  //   dispatch(loaderEnable())  
  //   return http.delete(`/auto_suggestions`, { data: dataObj })
  //   .finally(()=>dispatch(loaderDisable()));
  // };

  return { 
    getById, 
  }

};

export default ApiAutoSuggestions;
