import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
    name : 'toast',
    initialState: {},
    reducers: {
        callToast(state, action){
            // console.log(action , 'adfadf');
            state[0] = action.payload
        } 
    }
})

// console.log('toastSlice', toastSlice);
 
export const {callToast} = toastSlice.actions
export default toastSlice.reducer;