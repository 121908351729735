import { useDispatch, useSelector } from "react-redux";
import httpData from "./http";
import { loaderDisable, loaderEnable } from "src/store/features/loaderSlice";

const ApiPermitOrders = () => {
  const dispatch = useDispatch();
  const divisionReduxData = useSelector((state) => state.division)[0];
  const { http } = httpData(divisionReduxData.division_id);

  const division_param = {
    division_id: divisionReduxData.division_id,
  };

 
  const getByCustomUrl = async (data) => {
    // Vehicle Details Using by VIN : 
    // `vehicle_details?vin_number=Vin Number`

    // Trip URL : 
    // `trip_details/?start_city=cityName&start_zip=ZipCode&end_city=CityName&end_zip=zipCode`

    // Driver Suggestion : 
    // `driver_suggestion`

    dispatch(loaderEnable()); 
    return http.get(`/permit_orders/${data}`)
      .finally(() => dispatch(loaderDisable()));
  }; 

  const getByParams = (data) => {
    dispatch(loaderEnable())   
    return http.get(`/permit_orders`, {params: data})
    .finally(()=>dispatch(loaderDisable()));
  };

  const getById = (data) => {
    dispatch(loaderEnable());
    return http
      .get(`/permit_orders/${data}`)
      .finally(() => dispatch(loaderDisable()));
  };

  const getNotes = (data) => {
    dispatch(loaderEnable());
    return http
      .get(`/permit_orders/get_notes`, {params: data})
      .finally(() => dispatch(loaderDisable()));
  }; 
  const addNotes = (data) => {
    dispatch(loaderEnable());
    return http
      .put(`/permit_orders/add_notes`, data)
      .finally(() => dispatch(loaderDisable()));
  };
  const updateByFlags = (data) => {
    dispatch(loaderEnable());
    return http
      .put(`/permit_orders/update_flags`, data)
      .finally(() => dispatch(loaderDisable()));
  };
  const add = (data) => {
    dispatch(loaderEnable());
    return http
      .post(`/permit_orders`, data)
      .finally(() => dispatch(loaderDisable()));
  };
  const update = (data) => {
    dispatch(loaderEnable());
    return http
      .put(`/permit_orders`, data)
      .finally(() => dispatch(loaderDisable()));
  };
  const remove = (dataObj) => {
    dispatch(loaderEnable());
    return http
      .delete(`/permit_orders`, { data: dataObj })
      .finally(() => dispatch(loaderDisable()));
  };

  return { 
    getByCustomUrl,
    getById,
    getNotes,
    addNotes,
    getByParams,
    updateByFlags,
    add,
    update,
    remove,
  };
};

export default ApiPermitOrders;
