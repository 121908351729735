import { createSlice } from "@reduxjs/toolkit";


const loaderSlice = createSlice({
    name : 'loader',
    initialState: {count:0},
    reducers: {
        loaderEnable(state, action){
            state.count++
        },
        loaderDisable(state, action){
            (state.count > 0) && state.count--
        },
        loaderReset(state, action){
            state.count = 0
        }
    }
})
 
export const {loaderEnable, loaderDisable, loaderReset} = loaderSlice.actions
export default loaderSlice.reducer;