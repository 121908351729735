import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice';
 

const ApiCompanies = ()=>{ 

  
  const dispatch = useDispatch()
  const divisionReduxData = useSelector((state)=>state.division)[0]   
  const {http} = httpData(divisionReduxData.division_id)
 
  const division_param = {
    division_id : divisionReduxData.division_id
  }


  const getAll = () => {
    dispatch(loaderEnable())  
    return http.get('/companies' )
    .finally(()=>dispatch(loaderDisable()));
  };
  const getById = (data) => {
    dispatch(loaderEnable())  
    return http.get(`/companies/${data}` )
    .finally(()=>dispatch(loaderDisable()));
  };
  const add = (data) => {
    dispatch(loaderEnable())  
    return http.post(`/companies`, data )
    .finally(()=>dispatch(loaderDisable()));
  };
  const update = (data) => {
    dispatch(loaderEnable())  
    return http.put(`/companies`, data )
    .finally(()=>dispatch(loaderDisable()));
  };
  const remove = (dataObj) => {
    dispatch(loaderEnable())  
    return http.delete(`/companies`, { data: dataObj })
    .finally(()=>dispatch(loaderDisable()));
  };

  return {
    getAll,
    getById,
    add,
    update,
    remove,
  };

};

export default ApiCompanies;
