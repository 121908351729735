
import { createSlice } from "@reduxjs/toolkit";
 
const initialState = [{
    division_id : null, 
    division_name : '', 
    company_id : null, 
    company_name : '',
    dotco_id : null, 
    dotco_name : '', 
  }]

const divisionSlice = createSlice({
    name: 'division', 
    initialState: initialState,
    reducers: {
        updatedivisionstore(state, action) { 
            // console.log('division payload', action);
            state[0] = action.payload
            
        },
        resetDivisionStore(state, action) { 
            // console.log('division payload', action);
            state = initialState
        }
    }
})

// console.log('divisionSlice', divisionSlice);

export const{updatedivisionstore, resetDivisionStore} = divisionSlice.actions
export default divisionSlice.reducer