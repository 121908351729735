import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';  
const API_BASE_URL = process.env.REACT_APP_FLEET_BACKEND_API_URL;  
 
const HttpData = (divisionId=null)=> { 
    //  console.log("divisionId", divisionId);
  // const userdata = useSelector(state=>state.division)
  // let jsonData = localStorage.getItem("persist:root")
  // console.log("--- httpData jsonData ---", jsonData);
  // console.log("--- httpData division_id ---", JSON.parse(JSON.parse(jsonData).division)[0].division_id);
  // let division_id = null
  // if(jsonData == null || jsonData == undefined) {
  //   division_id = null
  // }else {
  //   division_id = JSON.parse(JSON.parse(jsonData).division)[0].division_id 
  // }

  const http = axios.create({
    withCredentials: true,    
    baseURL: API_BASE_URL,
    headers: { 
      'content-type': 'multipart/form-data',   
    }, 
    params: { 
      division_id: divisionId
    }
  }); 

  return {http}; 
}

export default HttpData
