import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';

import cartReducer from './features/cartSlice'
import sidebarReducer from './features/sidebarSlice'
import divisionReducer from './features/divisionSlice'
import errorsReducer from './features/errorsSlice';
import loaderSlice from './features/loaderSlice';
import toastSlice from './features/toastSlice';
import userdataSlice from './features/userdataSlice';
import dailyReportDateSlice from './features/dailyReportDateSlice';
import skipApiResponseProcessSlice from './features/skipApiResponseProcessSlice';
 
// console.log('divisionReducer', divisionReducer);
const reducer = combineReducers({
  cart: cartReducer,
  sidebar : sidebarReducer,
  division: divisionReducer,
  serverErrors: errorsReducer,
  dailyReportDate: dailyReportDateSlice,
  loader : loaderSlice,
  toast : toastSlice,
  userdata : userdataSlice,
  skipApiResponseProcess : skipApiResponseProcessSlice,
})

const persistConfig  = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})
 
export default store
