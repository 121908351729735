import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice'; 
 
const ApiVehicles= () => {
  
  const dispatch = useDispatch()
  const divisionReduxData = useSelector((state)=>state.division)[0]   
  const {http} = httpData(divisionReduxData.division_id)

  const division_param = {
      division_id : divisionReduxData.division_id
  }

  const getAll = () => {
    dispatch(loaderEnable())  
    return http.get('/vehicles')
    .finally(()=>dispatch(loaderDisable()));
  };
  const getById = (data) => {
    dispatch(loaderEnable())   
    return http.get(`/vehicles/${data}`)
    .finally(()=>dispatch(loaderDisable()));
  };
 
  const getVehicleDetailsByVin = (data) => {
    dispatch(loaderEnable())   
    return http.get(`/vehicles/vehicle_details?vin_number=${data}`)
    .finally(()=>dispatch(loaderDisable()));
  };
  const getByParams = (data) => {
    dispatch(loaderEnable())   
    return http.get(`/vehicles`, {params: data})
    .finally(()=>dispatch(loaderDisable()));
  };
  const add = (data) => {
    dispatch(loaderEnable())  
    return http.post(`/vehicles`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  const update = (data) => {    
    dispatch(loaderEnable())  
    return http.put(`/vehicles`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  const updateUpload = (data) => {
    dispatch(loaderEnable())  
    return http.put(`/vehicles/uploads`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  const updateByUrl = (url, data) => {
    dispatch(loaderEnable())  
    return http.put(url, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  const approve = (data, url="") => {
    dispatch(loaderEnable())  
    return http.put(`/vehicles/${url}`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  const remove = (dataObj) => {
    dispatch(loaderEnable())  
    return http.delete(`/vehicles`, { data: dataObj})
    .finally(()=>dispatch(loaderDisable()));
  };
  const removeByUrl = (data) => { 
    dispatch(loaderEnable())  
    return http.delete(data)
    .finally(()=>dispatch(loaderDisable()));
  };
  
  return {
    getAll,
    getById,
    getVehicleDetailsByVin,
    getByParams,
    add,
    update,
    updateUpload,
    updateByUrl,
    approve,
    remove,
    removeByUrl
  } 

} 

export default ApiVehicles;
