import React from 'react'; 
import Roles from './auth/roles';
import PermitOrderForm from './modules/permit/permit_order_form';

// DotCo
const DailyReport = React.lazy(() => import ('./modules/daily-repot/daily_report'));
const FleetStatus = React.lazy(() => import ('./modules/fleet-status/fleet_status'));
const Companies = React.lazy(() => import ('./modules/dotcos/companies'));
const Dotcos = React.lazy(() => import ('./modules/dotcos/dotcos'));
const Divisions = React.lazy(() => import ('./modules/dotcos/divisions'));
const Vendors = React.lazy(() => import ('./modules/vendors/vendors'));
const Vehicle = React.lazy(() => import ('./modules/vehicles/vehicles'));
const VehiclesFormContainer = React.lazy(() => import ('./modules/vehicles/vehicles_form_container'));
const VehicleVendors = React.lazy(() => import ('./modules/vehicle_vendors/vehicle_vendors'));
const VehicleVendorsDoc = React.lazy(() => import ('./modules/vehicle_vendors/vehicle_vendors_doc'));
const VehicleDoc = React.lazy(() => import ('./modules/vehicles/vehicle_doc'));
const About = React.lazy(() => import ('./modules/about/about'));

const AllReport = React.lazy(() => import ('./modules/reports/all_reports'));
const AllDailyReport = React.lazy(() => import ('./modules/reports/daily'));
const Billing = React.lazy(() => import ('./modules/reports/billing'));
const ActiveVehicleReport = React.lazy(() => import ('./modules/reports/active_vehicle_report'));
const InActiveVehicleReport = React.lazy(() => import ('./modules/reports/in_active_vehicle_report'));
const ActiveInterstate = React.lazy(() => import ('./modules/reports/active_interstate'));
const InActiveInterstate = React.lazy(() => import ('./modules/reports/inactive_interstate'));
const NeedingAttention = React.lazy(() => import ('./modules/reports/needing_attention'));
const DotcoMaintenanceReport = React.lazy(() => import ('./modules/reports/dotco-maintenance_report')); 
const PermitOrder = React.lazy(() => import ('./modules/permit/permit_order')); 

const Users = React.lazy(() => import ('./modules/vehicles/users'));
const OnlyTutorial = React.lazy(() => import ('./onlyTutorials/onlyTutorials'));

const routes = [
    {
        path: '/onlytutorial',
        name: 'Only Tutorial',
        element:OnlyTutorial,
        exact: true,
    }, { 
        path: '/',
        exact: true, 
        element:FleetStatus,
        name: 'Home'
    }, {
        path: '/vehicle-status',
        name: 'Vehicle Status',
        element:FleetStatus,
        exact: true
    }, {
        path: '/daily-report',
        name: 'Daily Report',
        element: DailyReport,
        exact: true
    }, {
        path: '/vendors',
        name: 'Vendors',
        element: Vendors,
        exact: true, 
    }, {
        path: '/companies',
        name: 'Companies',
        element: Companies,
        exact: true
    }, {
        path: '/dotcos',
        name: 'Dotcos',
        element: Dotcos,
        exact: true
    }, {
        path: '/divisions',
        name: 'Divisions',
        element: Divisions,
        exact: true
    }, {
        path: '/vehicles',
        name: 'Vehicle',
        element: Vehicle,
        exact: true,
        permission : [ 
            Roles.ADMIN, 
        ]  
    // }, {
    //     path: '/vehicles/form/add/vehicles',
    //     name: 'Add Vehicle',
    //     element: VehiclesFormContainer,
    //     exact: true,
    //     permission : [ 
    //         Roles.ADMIN, 
    //     ]  
    }, {
        path: '/vehicles/form/add/:backTo',
        name: 'Add Vehicle',
        element: VehiclesFormContainer,
        exact: true,
        permission : [ 
            Roles.ADMIN, Roles.USER
        ]  
 
    }, {
        path: '/vehicles/form/edit/:slug/:backTo',
        name: 'Edit Vehicle',
        element: VehiclesFormContainer,
        exact: true
    }, {
        path: '/vehicles/form/edit/:slug/:backTo/:activeTab',
        name: 'Edit Vehicle',
        element: VehiclesFormContainer,
        exact: true
    }, {
        path: '/vehicle/:vehicleId/vehicle_vendors/:vehicleVendorId',
        name: 'Edit Vehicle',
        element: VehicleVendors,
        exact: true
    }, {
        path: '/vehicle/:vehicleId/vehicle_vendors/:vehicleVendorId/doc/:docType',
        name: 'Edit Vehicle',
        element: VehicleVendorsDoc,
        exact: true
    }, {
        path: '/vehicle/:vehicleId/doc/:docType',
        name: 'Edit Vehicle',
        element: VehicleDoc,
        exact: true
    }, {
        path: '/reports/all',
        name: 'Fleet',
        element: AllReport,
        permission : [ 
            Roles.ADMIN, 
        ]  
    }, {
        path: '/reports/all_daily',
        name: 'Fleet',
        element: AllDailyReport,
        permission : [ 
            Roles.ADMIN, 
        ]  
    }, {
        path: '/reports/billing',
        name: 'Fleet',
        element: Billing,
        permission : [ 
            Roles.ADMIN, 
        ]  
    }, {
        path: '/reports/active',
        name: 'Fleet',
        element: ActiveVehicleReport, 
    }, {
        path: '/reports/inactive',
        name: 'Fleet',
        element: InActiveVehicleReport, 
    }, {
        path: '/reports/needing-attention',
        name: 'Fleet',
        element: NeedingAttention, 
    }, {
        path: '/reports/active-interstate',
        name: 'Fleet',
        element: ActiveInterstate, 
    }, {
        path: '/reports/inactive-interstate',
        name: 'Fleet',
        element: InActiveInterstate, 
    }, {
        path: '/reports/dotco-maintenance',
        name: 'Fleet',
        element: DotcoMaintenanceReport, 
    }, {
        path: '/permit/order-list',
        name: 'Permit Order',
        element: PermitOrder, 
    }, {
        path: '/permit/order-form',
        name: 'Permit Order Form',
        element: PermitOrderForm, 
    }, {
        path: '/about',
        name: 'Fleet',
        element: About,
        exact: true,  
    }, {
        path: '/users',
        name: 'Users',
        element: Users,
        exact: true, 
        permission : [ 
            Roles.ADMIN, 
        ]   
    } 
];

export default routes;
