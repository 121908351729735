import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice'; 

 

const ApiDropdowns = ()=>{
 
    const dispatch = useDispatch()
    const divisionReduxData = useSelector((state)=>state.division)[0]   
    const {http} = httpData(divisionReduxData.division_id)
    
    const division_param = {
        division_id : divisionReduxData.division_id
    }

    const getAll = (data) => {
        dispatch(loaderEnable())  
        return http.get(`/dropdowns`, {params: data}) 
        .finally(()=>dispatch(loaderDisable()));
    };

    return {getAll}
};

export default ApiDropdowns;
