import React, { Component, StrictMode, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import './scss/style.scss';
import { loaderReset } from './store/features/loaderSlice';
import { callToast } from './store/features/toastSlice';
import Cookies from 'universal-cookie';
import { setUserData } from './store/features/userdataSlice';
import User from './modules/user/user';
import UseCookiesValue from './helpers/useCookiesValue';
// import useCookiesValue  from './helpers/useCookiesValue';
 

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const FleetAccessNotAllow     = React.lazy(() => import('./views/pages/auth/AccessNotAuth'));
const Login     = React.lazy(() => import('./views/pages/login/Login'));
const Register  = React.lazy(() => import('./views/pages/register/Register'));
const Page404   = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500   = React.lazy(() => import('./views/pages/page500/Page500'));



const App = ()=> {
 
  const dispatch = useDispatch()
 
  useEffect(() => { 
    /// forcely reset the ////////////////
    /// If user will refresh the page.///////////
    dispatch(loaderReset())
    dispatch(callToast({}))
    // dispatch(setUserData({}))
    ///////////////////////////////////////////// 
  } , [])
     
  return (
    <BrowserRouter>
      <Suspense fallback={loading}> 
          <Routes>
            <Route exact path="/accessNotAuth" name="Login Page" element={<FleetAccessNotAllow />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/userdata" name="Get User Data" element={<User />} />
            <Route path="*" name="Home" element={<ProtectedRoutes />} />
          </Routes> 
      </Suspense>
    </BrowserRouter>
  )

}

export default App;
