import { createSlice } from "@reduxjs/toolkit";

const initialState = '';

const dailyReportDateSlice = createSlice({
    name : 'dailyReportDate',
    initialState,
    reducers: {
        updateDailyReportDate(state, action){
            // console.log('state, action',  state, action);
            return state = action.payload
        },    
        resetDailyReportDate(state, action){
            // console.log('state, action',  action);
            // state = state.filter((item)=> item.id !== action.payload)
        }
    }
})
 

export const{updateDailyReportDate, resetDailyReportDate} = dailyReportDateSlice.actions
export default dailyReportDateSlice.reducer