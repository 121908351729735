import { createSlice } from "@reduxjs/toolkit";
 
const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {value : true},
    reducers: {
        sidebartoggle(state, action) { 
            state.value = action.payload
        }
    }
})

// console.log('sidebarSlice', sidebarSlice);

export const {sidebartoggle} = sidebarSlice.actions
export default sidebarSlice.reducer