import {  useEffect, useState, useRef } from 'react';
import {
  CToaster,
  CToast,
  CToastHeader,
  CToastBody,
  CToastClose,
} from '@coreui/react';

const Toast = ({ data }) => {
  const { color = 'primary', content = '' } = data;
 
  const toaster = useRef();
  const toastHolder = (
    <CModal visible={visible} onClose={() => setVisible(false)}>
      {/* <CModalHeader>
        <CModalTitle>Modal title</CModalTitle>
      </CModalHeader> */}
      <CModalBody>{content}</CModalBody> 
    </CModal>
    // <CToast
    //   autohide={true}
    //   color={color}
    //   className="text-white align-items-center"
    // >
    //   <div className="d-flex">
    //     <CToastBody>{content}</CToastBody>
    //     <CToastClose className="me-2 m-auto" white />
    //   </div>
    // </CToast>
  );

  return <CModal visible={true} onClose={() => setVisible(false)}> 
    <CModalBody>{content}</CModalBody> 
  </CModal>;
  // return <CToaster ref={toaster} push={toastHolder} placement="bottom-end" />;
};
export default Toast;
