import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faUpLong } from '@fortawesome/free-solid-svg-icons';

// console.log('Fontawesome ---', faDownLong);

const SortCaret = (order) => {
  if (!order)
    return (
      <span style={{ marginLeft: '0.5rem' }}>
        <FontAwesomeIcon icon={faDownLong} 
        style={{ height:"0.8rem" }} />
        <FontAwesomeIcon icon={faUpLong} 
        style={{ height:"0.8rem" }} />
      </span>
    );
  else if (order === 'asc')
    return (
      <FontAwesomeIcon
        color="red"
        style={{ marginLeft: '0.5rem', height:"0.8rem" }}
        icon={faDownLong}
      />
    );
  else if (order === 'desc')
    return (
      <FontAwesomeIcon
        color="red"
        style={{ marginLeft: '0.5rem', height:"0.8rem" }}
        icon={faUpLong}
      />
    );
  return null;
};

export default SortCaret;
