import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

function PixelTracking() {
    const userdata = useSelector((state)=> state.userdata)  
  return (
    <img src={userdata.length > 0 ? userdata[0]['pixel_url'] : ''} style={{display:"none"}} alt="pixel tracker" />
  )
}

export default PixelTracking