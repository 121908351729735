import classNames from "classnames";
import { useEffect, useState } from "react";


const FormFieldError = ({name, errors, error, className=""})=>{  
    return (
        <span className={"text-danger "+className}> 
            {errors && name ? errors[name]?.message : error ? error.message :  ''}
        </span>
    )
}

export default FormFieldError;