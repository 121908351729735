import { createSlice } from "@reduxjs/toolkit";
 
const initialData = {
    status : null, 
    errorText : null
  }
const errorSlice = createSlice({
    name: 'errors',
    initialState: {0:{}},
    reducers: { 
        updateerrorstore(state, action) {  
            console.log('error Slice payload', action);
            state = action.payload

        }
    }
})

// console.log('errorSlice', errorSlice);

export const {updateerrorstore} = errorSlice.actions
export default errorSlice.reducer