import { createSlice } from "@reduxjs/toolkit"; 
 
const skipApiResponseProcessSlice = createSlice({
    name : 'skipApiResponseProcess',
    initialState: {count:0},
    reducers: {
        setSkipApiResponseProcess(state, action){   
            state.count = action.payload
            // state.count++
        }, 
    }
})

// console.log('toastSlice', toastSlice);
 
export const {setSkipApiResponseProcess} = skipApiResponseProcessSlice.actions
export default skipApiResponseProcessSlice.reducer;