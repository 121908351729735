import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice'; 

const ApiFleetStatus = () => {
 
  const dispatch = useDispatch()
  const divisionReduxData = useSelector((state)=>state.division)[0]   
  const {http} = httpData(divisionReduxData.division_id)

  const division_param = {
      division_id : divisionReduxData.division_id
  }

  const getAll = () => {
    dispatch(loaderEnable())  
    return http.get('/fleet_status')
    .finally(()=>dispatch(loaderDisable()));
  };

  const getById = (data) => {
    dispatch(loaderEnable())  
    return http.get(`/fleet_status/`, {params: data})
    .finally(()=>dispatch(loaderDisable()));
  };
  
  const getByParam = (data) => {
    dispatch(loaderEnable())  
    return http.get(`/fleet_status/${data}`)
    .finally(()=>dispatch(loaderDisable()));
  };
  
  const add = (data) => {
    dispatch(loaderEnable())  
    return http.post(`/fleet_status/add_new`, data)
    .finally(()=>dispatch(loaderDisable()));
  };

  const update = (data) => {
    dispatch(loaderEnable())  
    return http.put(`/fleet_status`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  const updateWithFlag = (data) => {
    dispatch(loaderEnable())  
    return http.put(`/fleet_status/edit_flags`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  
  const remove = (dataObj) => { 
    return http.delete(`/fleet_status`, { data: dataObj})
    .finally(()=>dispatch(loaderDisable()));
  };
  
  return {
    getAll,
    getById,
    getByParam,
    add,
    update, 
    updateWithFlag, 
    remove, 
  };
};

export default ApiFleetStatus;
