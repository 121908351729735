import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const { ExportCSVButton } = CSVExport;
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Suspense,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";

var nameFilter;
var priceFilter;
var stockFilter;
var originFilter;

const DataTable = ({
  columns,
  rows,
  title,
  responsive = false,
  isStriped = true,
  isSearch = true,
  isCsv = true,
  isPagination = true,
  paginationSize,
  keyField = "id",
  resetSearch=false,
  ...rest
}) => {
  
  const divisionReduxData = useSelector((state) => state.division)[0];
  const { SearchBar, ClearSearchButton } = Search;

  const searchBarButton = useRef()

  const [rowDatas, setRowDatas] = useState([]);
  const [tableRenderType, setTableRenderType] = useState(1);

  const rowsList = useMemo(() => rows, [rows]);

  useEffect(() => {
    setRowDatas(rows);
    setTableRenderType((prevState) => {
      console.log("prevState", prevState);
      if (prevState == 1) {
        return 2;
      } else {
        return 1;
      }
    });

    //clear search bar when resetSearch is true and data is changed
    resetSearch ? searchBarButton.current.click() : '' 
  }, [rows]);

  useEffect(() => {
    //clear search bar when data will change
    setTimeout(() => {
      searchBarButton?.current?.click() 
    }, 1000);
  }, [divisionReduxData]);

  // function customMatchFunc({ searchText, value, column, row }) {
  //   if (typeof value !== "undefined") {
  //     return value.startsWith(searchText);
  //   }
  //   return false;
  // }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = useMemo(() => {
    return {
      paginationSize: 10,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: isPagination ? !paginationSize ? [
        {
          text: "15",
          value: 15,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "75",
          value: 75,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: rows ? rows.length : 0,
        },
      ]: [
        {
          text: paginationSize,
          value: paginationSize,
        }] : rows.length, // A numeric array is also available. the purpose of above example is custom the text
    };
  }, [rows, customTotal]);

  const expandRow = {
    parentClassName: (isExpanded, row, rowIndex) => {
      if (rowIndex > 2) return "parent-expand-foo";
      return "parent-expand-bar";
    },
    renderer: (row) => <div>...</div>,
  };

  const TableRender = () => {};

  // const clearAllFilter = () => {
  //   nameFilter("");
  //   priceFilter("");
  //   originFilter("");
  //   stockFilter("");
  // };

  return (
    <ToolkitProvider
      keyField={keyField}
      columns={columns}
      data={rowsList}
      search
    >
      {(props) => (
        <div className="datatableContainer">
          <div className="row">
            <div className="col-md-7 col-xs-12 ">
              <div className="titleContainer">
                {title ? <h3>{title}</h3> : ""}
              </div>
            </div>
            <div className="col-md-5 col-xs-12">
              <div className="searchBarContainer">
                {isSearch && (
                  <>
                    <SearchBar
                      {...props.searchProps}
                      style={{ width: "230px" }}
                      onkeydown={(event) => {
                        if (event.keyCode == 13) {
                          event.preventDefault();
                          return false;
                        }
                      }}
                      autosuggestion={false}
                    />
                    <ClearButton
                      ref={searchBarButton}
                      {...props.searchProps}
                      // clearAllFilter={clearAllFilter}
                      style={{display:'none'}}
                    />
                  </>
                )}
                {isCsv && (
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{ marginLeft: "1rem" }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} /> Export
                  </ExportCSVButton>
                )}
              </div>
            </div>
          </div>
          <div
            className={`tableContainer ${responsive ? "tableResponsive" : ""}`}
          >
            <BootstrapTable
              bootstrap5
              {...props.baseProps}
              pagination={isPagination && paginationFactory(options)}
              striped={isStriped}
              responsive={true}
              hover
              headerClasses="table_sticky_header"
              {...rest}
            /> 

          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

const ClearButton = forwardRef(function ClearButton(props, ref) {
  const handleClick = () => {
    props?.onSearch("");
    // props.clearAllFilter();
  };
  return (
    <Button
      {...props}
      ref={ref}
      variant="secondary"
      onClick={handleClick} 
    >
      Clear
    </Button>
  );
});
export default memo(DataTable);
