import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice'; 
 
const ApiFile = ()=>{

  
  const dispatch = useDispatch()
  const divisionReduxData = useSelector((state)=>state.division)[0]   
  const {http} = httpData(divisionReduxData.division_id)

  const division_param = {
      division_id : divisionReduxData.division_id
  }

  const getAll = () => {
    dispatch(loaderEnable())  
    return http.get('/files')
    .finally(()=>dispatch(loaderDisable()));
  };
  const getViewFile = (filename) => { 
    dispatch(loaderEnable())  
    return http.get(`/files/get-signed-file-url?filename=${filename}`)
    .finally(()=>dispatch(loaderDisable()));
  };
  const add = (data) => {
    dispatch(loaderEnable())  
    return http.post(`/files`, data)
    .finally(()=>dispatch(loaderDisable()));
  };

  const update = (data) => { 
    dispatch(loaderEnable())     
    return http.put(`/files`, data)
    .finally(()=>dispatch(loaderDisable()));
  };
  
  ////////// Download ///////////
  const download = (filename) => { 
    dispatch(loaderEnable())  
    return http.get(`/files//download-file?filename=${filename}`)
    .finally(()=>dispatch(loaderDisable()));
  };
  return {
    getAll,
    getViewFile,
    add,
    update, 
    download
  } 
} 

export default ApiFile;
