import React, { useEffect, useState } from "react";
import Toast from "src/modules/shared/components/toast";
import { useDispatch, useSelector } from "react-redux";
import { callToast } from "src/store/features/toastSlice"; 
import { useNavigate } from "react-router-dom";
import { setUserData } from "src/store/features/userdataSlice";
import { useAuth } from "./useAuth";
import { useForm } from "react-hook-form";
import { setSkipApiResponseProcess } from "src/store/features/skipApiResponseProcessSlice";
import UseCookiesValue from './useCookiesValue';

const SESSION_COOKIES_NAME = process.env.REACT_APP_SESSION_COOKIES_NAME;
const BACKEND_BASE_URL = process.env.REACT_APP_FLEET_BACKEND_BASE_URL;
const USER_LOGGED_OUT_STATUS = process.env.REACT_APP_USER_LOGGED_OUT_STATUS;
const NO_FLEET_ACCESS_STATUS = process.env.REACT_APP_NO_FLEET_ACCESS_STATUS;
const SESSION_TIME_OUT_STATUS = process.env.REACT_APP_SESSION_TIME_OUT_STATUS; 
const SESSION_TIME_OUT_COOKIES_NAME = process.env.REACT_APP_SESSION_TIME_OUT_COOKIES_NAME;
// console.log('SESSION_TIME_OUT_STATUS',SESSION_TIME_OUT_STATUS);

const useApiMessageHandler = (err = []) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authHelper = useAuth();
  const userdata = useSelector((state) => state.userdata);
  const skipApiResponseProcess = useSelector((state) => state.skipApiResponseProcess.count);
  const { deleteCookiesData } = UseCookiesValue(); 

  const backendMessagesController = (
    apisData,
    errorsUpdateHandler = "",
    isErrorTypeMain = false
  ) => {
    let messageContainer = "";
    let fieldSpecificErrorContainer = {};
    let toastColor = "";
    const { getCookiesData } = UseCookiesValue();

    // const session_time_out_cookie = getCookiesData(SESSION_TIME_OUT_COOKIES_NAME);
    const session_time_out_cookie = getCookiesData('isSessionTimedOut');
 
    // IF LOGOUT COOKIE IS SET
    if (session_time_out_cookie || apisData[0].response.msg == "Session Timed Out." || apisData[0].status == '102') {
 
      console.log('Session Time Out --- authHelper.logout() triggered (UseApiMessageHandle)');
      dispatch(setSkipApiResponseProcess(1));
      authHelper.logout(); 
      return;
    }

    if(!skipApiResponseProcess){ 
      if (apisData.length > 0) {
        for (let key in apisData) {
          const dataBykey = apisData[key];
 
          if (dataBykey.status == USER_LOGGED_OUT_STATUS) { 
            //////// if user session expire ////////
            /// then backend send status code 10 ////
            /// in this situdation resetAuth will //
            /// excute to reset the auth cookies ///
            // console.log('USER_LOGGED_OUT_STATUS --- authHelper.resetAuth() triggered (UseApiMessageHandle)');
            authHelper.resetAuth();
            ////////////////////////////////////////
            ////////////////////////////////////////
          } else if (dataBykey.status == SESSION_TIME_OUT_STATUS) {
            // console.log('apisData SESSION_TIME_OUT_STATUS', SESSION_TIME_OUT_STATUS);
            // console.log('SESSION_TIME_OUT_STATUS ---  (UseApiMessageHandle)');
            // if fleet Session Timed Out then trigger Logout //
          /*  authHelper.logout(); */
          } else if (
            dataBykey.status == 1 ||
            dataBykey.status == 2 ||
            dataBykey.status == 3 ||
            dataBykey.status == 4
          ) {
            toastColor =
              dataBykey.status == 1
                ? "success"
                : dataBykey.status == 2
                ? "warning"
                : dataBykey.status == 3
                ? "info"
                : dataBykey.status == 4
                ? "dark"
                : "secondary";
            let message = dataBykey.response.message
              ? dataBykey.response.message
              : "";
            if (message.length > 0) {
              messageContainer = messageContainer.concat("\n ", message.trim());
            }
          } else if (dataBykey.status == 0) {
            const resErrors = dataBykey.response.errors;
            for (let error in resErrors) {
              if (error !== "main") {
                if (isErrorTypeMain) {
                  toastColor = "danger";
                  messageContainer = messageContainer.concat(
                    "\n ",
                    resErrors[error]
                  );
                } else {
                  fieldSpecificErrorContainer[error] = {
                    type: "manual",
                    message: resErrors[error],
                  };
                }
              } else {
                toastColor = "danger";
                const resErrors = dataBykey.response.errors
                  ? dataBykey.response.errors
                  : "";

                let i_lenght = 0;
                for (let key in resErrors) {
                  i_lenght++;
                  if (key == "main") {
                    let errorText = resErrors[key];
                    if (errorText.length > 0) {
                      messageContainer = messageContainer.concat(
                        "\n ",
                        errorText.trim()
                      );
                    }
                  }
                }
              }
            }
            // console.log('--- messageContainer ----', messageContainer);

            // console.log('--- errorContainer ----', errorContainer);
            // console.log(errorsUpdateHandler ? 'True' : 'False');
            // errorsUpdateHandler ? errorsUpdateHandler(errorContainer) : errorsUpdateHandler({});
          }
        }

        // console.log('--- messageContainer messageContainer ----', messageContainer);
        // console.log('messageContainer.length', messageContainer.length);
        // console.log('--- fieldSpecificErrorContainer ', fieldSpecificErrorContainer);
        let toastData = {};
        if (messageContainer.length > 1) {
          toastData = {
            color: toastColor,
            content: messageContainer,
          };
          dispatch(callToast(toastData));
        }
        if (Object.keys(fieldSpecificErrorContainer).length > 0) {
          errorsUpdateHandler(fieldSpecificErrorContainer);
        }
      }
    }

  };

  // return apisBackendErrorsHandler;
  const catchErrorsController = (
    apiName,
    serverErrors,
    displayType = "toast"
  ) => {
    const isDevelopmentEnv =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development";

    if (serverErrors.length > 0 || Object.keys(serverErrors).length > 0) {
      if (isDevelopmentEnv == true) {
        console.log(`Api's Catch Errors -- (${apiName}) --`, serverErrors); 
      }
    }
  };

  return { backendMessagesController, catchErrorsController };
};

export default useApiMessageHandler;
