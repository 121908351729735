import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownLong, faUpLong} from '@fortawesome/free-solid-svg-icons';

// console.log('Fontawesome ---', faDownLong);

const ColorVarient = (type, isClass=false) => {
 
    // console.log('>--- type --->', type);
    if (type == 'red' || type == 'danger') {
        return isClass ? 'custom_bg_danger' : '#ffe3e3';
    }else if (type == 'yellow' || type == 'warning') {
        return isClass ? 'custom_bg_warning' : '#ffefcf';
    }else if (type == 'green' || type == 'success') {
        return isClass ? 'custom_bg_success' : '#d4ffcf';
    }else if (type == 'gray') {
        return isClass ? 'custom_bg_light_gray' : '#ececec';
    }

}

export default ColorVarient;