import { useDispatch, useSelector } from 'react-redux';
import httpData from './http';
import { loaderDisable, loaderEnable } from 'src/store/features/loaderSlice'; 
 
const ApiDriver= () => {
  
  const dispatch = useDispatch()
  const divisionReduxData = useSelector((state)=>state.division)[0]   
  const {http} = httpData(divisionReduxData.division_id)

  const division_param = {
      division_id : divisionReduxData.division_id
  }

  const getAutoSuggestions = (data) => {
    dispatch(loaderEnable())  
    return http.get(`/drivers/driver_suggestion?term=${data}`)
    .finally(()=>dispatch(loaderDisable()));
  };
  const getById = (data) => {
    dispatch(loaderEnable())   
    return http.get(`/drivers/driver_details?id=${data}`)
    .finally(()=>dispatch(loaderDisable()));
  };
 
  
  return {
    getAutoSuggestions,
    getById, 
  } 

} 

export default ApiDriver;
