import { method } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'

export const ControlledFormProvider = ({methods, onSubmit, children, ...other}) => {
  return (
    <FormProvider {...methods} >
        <form onSubmit={onSubmit} {...other}>
            {children}
        </form> 
    </FormProvider>
  )
}
