
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
 
const UseCookiesValue = () => { 
    const cookies = new Cookies();
  
    const getCookiesData = (cookiesName) => { 
        return cookies.get(cookiesName)
    }
    const deleteCookiesData = (cookiesName) => { 
        return cookies.remove(cookiesName)
    }
    return {getCookiesData, deleteCookiesData};
    
} 
 
 
export default UseCookiesValue